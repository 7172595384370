<template>
    <div class="basic-file">
        <!-- 巡检资料 -->
        <common-page :pageId="pageId"></common-page>
    </div>
</template>

<script>
import commonPage from './components/common'
export default {
    data() {
        return {
            // 巡检资料 传2
            pageId: 2
        }
    },
    components: {
        commonPage
    }
}
</script>

<style lang="scss" scoped>
    
</style>
